<template>
	<!-- 	<div style="margin-bottom: 20px;" align="center">
		<ins class="adsbygoogle" style="display:block;" data-ad-client="ca-pub-8147879111198627"
			data-ad-slot="4026586674" data-ad-format="auto" data-full-width-responsive="true"></ins>
	</div> -->
	<!-- 	<div style="margin-bottom: 20px;" align="center">
		<div class="card">
			<h5>
				<Tag class="mr-2" severity="success" value="Deal Records"></Tag>The price will go up with funtion
				upgrading, if you need, buy now! 功能持续更新，价格不断上涨，早买早优惠！<Tag class="mr-2" severity="success" value="成交记录">
				</Tag>
			</h5>
			<Carousel :value="deal_record_data" :numVisible="3" :numScroll="3" :circular="false"
				:responsiveOptions="carouselResponsiveOptions">
				<template #item="product">
					<div class="product-item">
						<div class="product-item-content">
							<div class="mb-3">
								<img :src="'images/dealrecords/' + product.data.image" :alt="product.data.name"
									height="400" />
							</div>
							<div>
								<h4 class="mb-1">
									{{ product.data.name }}
								</h4>
								<Tag style='font-size: 1.875rem;margin-top: 5px;margin-bottom: 5px;' class="mr-2"
									severity="info" :value="product.data.price"></Tag>
								<Rating :modelValue="product.data.rating" :readonly="true" :cancel="false" class="mb-2">
								</Rating>
							</div>
						</div>
					</div>
				</template>
			</Carousel>
		</div>
	</div> -->
	<div class="card grid" style="justify-content: space-around;padding: 0px;">
		<!-- <div class="card col-12 md:col-12"> -->
		<div class="col-12 md:col-8 p-3">
			<div id='try_demo_button'>
				<div class="shadow-2 p-3 h-full flex flex-column" style="border-radius: 6px;height: auto;"
					align="center">
					<Button v-if='language' @click='showDemo()' style="margin-top: 5px;padding: 10px;"
						label="Pull down the page or click me to try Live Demo" icon="pi pi-arrow-down"
						class="p-button-info" />
					<Button v-if='!language' @click='showDemo()' style="margin-top: 5px;padding: 10px;"
						label="下拉页面或点我体验在线 Demo" icon="pi pi-arrow-down"
						class="p-button-info" />
				</div>
			</div>
			<div v-if='show_client_map'>
				<!-- <img src="images/5.jpg" style='width: 100%;' alt="" /> -->
				<iframe ref='client_map' style='padding:0px;margin-bottom: -30px;' name="client_map" id="client_map"
					:src="'client_map/world_map.html?height='+client_map_height" frameborder="0" width="100%" :height='client_map_height+"px"'></iframe>
			</div>
		</div>
		<div id='info_container' class="col-12 md:col-4">
			<div style="margin-top: 10px;">
				<AdminInfo></AdminInfo>
			</div>
			<div style="margin-top: 10px;padding: 0px;">
				<Payme></Payme>
			</div>

		</div>
	</div>
	<div class="card grid" style="justify-content: space-around;padding: 0px;margin-top: 20px;">
		<div v-if='language'  class="col-12 md:col-2 p-3" style="margin-bottom: -30px;">
			<Button @click='goToTutorialVideo("en")' style="width: 100%;" label="Tutorial Video" icon="pi pi-youtube" class="p-button-danger p-button-outlined mb-3" />
		</div>
		<div v-if='!language'  class="col-12 md:col-2 p-3">
			<Button @click='goToTutorialVideo("zh_cn")' style="width: 100%;" label="操作视频教程" icon="pi pi-youtube" class="p-button-help p-button-outlined mb-3" />
		</div>
		<div v-if='!language' class="col-12 md:col-10 p-3">
			<a title='点我试用并了解产品详情'
				href="https://www.elasticpdf.com/demo" target="_blank">
			<Button style="width: 100%;" icon="pi pi-arrow-right"  iconPos="right" label="如需像 Adobe Acrobot 保存标准批注和注释文本到 PDF 中, 欢迎使用我们的专业产品 ElasticPDF" class="p-button-info p-button-raised mb-3" />
			</a>
		</div>
		<div v-if='language' class="col-12 md:col-10 p-3">
			<a title='click to learn more and try'
				href="https://www.elasticpdf.com/demo" target="_blank">
			<Button style="width: 100%;" icon="pi pi-arrow-right"  iconPos="right" label="If you need to save standard annotation and comments text into PDF like Adobe Acrobat, welcome to try our professional product ElasticPDF" class="p-button-info p-button-raised mb-3" />
			</a>
		</div>
		<div v-if='!pdf_url' class="col-12 md:col-12" style="margin-top: -30px;">
			<iframe ref='pdfjs_viewer_1' name="pdfjs_container" id="pdfjs_container"
				src='pdfjs-legacy-latest/web/test.html' frameborder="0" width="100%" :height="pdf_height"></iframe>
		</div>
		<div v-if='pdf_url' class="col-12 md:col-12" style="margin-top: -30px;">
			<iframe ref='pdfjs_viewer_1' name="pdfjs_container" id="pdfjs_container"
				:src="'pdfjs-legacy-latest/web/viewer.html?file='+pdf_url" frameborder="0" width="100%" :height="pdf_height"></iframe>
		</div>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	// import GoogleAds from './GoogleAds.vue';
	import AdminInfo from './AdminInfo.vue';
	// import DealRecords from './DealRecords.vue';
	import ReadFileService from '../service/ReadFileService.js';
	import Payme from './Payme.vue';
	export default {
		name: '',
		components: {
			// 'GoogleAds': GoogleAds,
			'AdminInfo': AdminInfo,
			// 'DealRecords': DealRecords,
			'Payme': Payme,
		},
		data() {
			return {
				// pdf_path: 'tutorial.pdf',
				pdf_path:'tutorial.pdf',
				pdf_height: "720px",
				client_map_height: "620px",
				show_client_map:true,
				pdf_url: null,
				carouselResponsiveOptions: [{
						breakpoint: "1024px",
						numVisible: 3,
						numScroll: 3,
					},
					{
						breakpoint: "768px",
						numVisible: 2,
						numScroll: 2,
					},
					{
						breakpoint: "560px",
						numVisible: 1,
						numScroll: 1,
					},
				],
				deal_record_data: [{
					"id": "1001",
					"code": "f230fh0g4",
					"name": "Pierpaolo* from Italy",
					"description": "Bought source code",
					"image": "Pierpaolo.jpg",
					"price": "$ 200",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1002",
					"code": "f230fh0g5",
					"name": "Zack.Fair from China",
					"description": "Bought source code",
					"image": "Zack Fair.jpg",
					"price": "￥999",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1000",
					"code": "f230fh0g3",
					"name": "98 && is a rainbow from China",
					"description": "Bought encrypted code",
					"image": "98_is.jpg",
					"price": "￥66+66",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1002",
					"code": "f230fh0g5",
					"name": "Pranjul* from India",
					"description": "Bought encrypted code",
					"image": "Pranjul.jpg",
					"price": "$ 9.99",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}],
				video:{
					"en":"???????",
					"zh_cn":"???????"
				},
			}
		},
		// networkService: null,
		created() {
			//实例化NetWorkService对象
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			// window.addAds();
			// window.addAds();
			let vm = this;
			// vm.resizeWin();
			// //屏幕变化时
			window.onresize = () => {
				vm.show_client_map=false;
				setTimeout(function(){
					vm.show_client_map=true;
				},500);
				// return (() => {
				// 	vm.resizeWin();
				// })();
			};

			// this.majorService.getMajorsNameAndId().then(data => {
			// 	this.majors = data;
			// });
			var client_map_container=document.getElementById('info_container');
			var try_demo_button=document.getElementById('try_demo_button');
			this.client_map_height=client_map_container.offsetHeight-try_demo_button.offsetHeight-30;
			console.log('client_map_container',this.client_map_height);
			this.ReadFileService.getUpdateInfo().then(data => {
				this.video=data.video;
			});
		},
		methods: {
			//屏幕变化调整样式
			resizeWin() {
				// this.pdf_width = document.documentElement.scrollWidth-400+'px';
				var windowHeight = document.documentElement.clientHeight;
				if (windowHeight > 830) {
					this.pdf_height = '750px';
				} else {
					this.pdf_height = windowHeight - 90 + 'px';
				}
				// console.log('高度',this.pdf_height);
			},
			goToTutorialVideo(para){
				window.open(this.video[para],'_blank');
			},
			addPdfUrl() {
				if (this.pdf_url){				
					this.pdf_path = this.pdf_url;
				}else{
					alert('Please input the correct pdf URL 请输入正确PDF文件的URL');
				}
			},
			goToElasticPDF(){
				window.open('https://www.elasticpdf.com/demo','_blank');
			},
			showDemo() {
				var pdfjs_container_id = "pdfjs_container";
				var pdfjs_container = document.getElementById(pdfjs_container_id);
				pdfjs_container.scrollIntoView();
				var currentScroll = window.scrollY;

				// 设置滚动目标位置
				var targetScroll = currentScroll - 80;

				// 使用平滑滚动来滚动到目标位置
				window.scrollTo({
					top: targetScroll,
					behavior: 'smooth'
				});
			}
		},
		computed: {
			// language() {
			// 	if (this.$appState.language=='en'){
			// 		// this.$refs.pdfjs_viewer_1.contentWindow.setLanguage('en');
			// 		console.log(this);
			// 		return true;
			// 	}else{
			// 		// this.$refs.pdfjs_viewer_1.contentWindow.setLanguage('zh-cn');
			// 		console.log(this);
			// 		return false;
			// 	}
			// }
			language() {
				if (this.$appState.language == 'en') {
					return true;
				} else {
					return false;
				}
			}

		}
	}
</script>

<style scoped lang="scss">
</style>